





















































































































import { Vue, Component } from 'vue-property-decorator'
import { apiUserLevelList } from '@/api/user/user'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiUserConfig, apiUserConfigSet } from '@/api/setting/user'
@Component({
    components: {
        MaterialSelect
    }
})
export default class UserSetting extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        default_avatar: '', // 默认头像
        scene: '', // 场景：user-用户设置；register-注册设置；withdraw-提现设置
        invite_open: 0, // 邀请下级：0-关闭 1-开启
        invite_ways: 1, // 邀请下级资格：1-全部用户可邀请 2-分销会员可邀请
        invite_appoint_user: [], // 邀请下级指定用户；1-分销会员；2-股东会员；3-代理会员（邀请下级资格为1时留空）
        invite_condition: 1 // 成为下级条件：1-邀请码
    }

    // eslint-disable-next-line camelcase
    invite_appoint_user: any = []
    userLevelLists: any = []
    // 图片
    images = {
        avater: require('@/assets/images/setting/img_shili_mine_touxiang.png')
    }
    /** E Data **/

    // 获取用户提现设置
    getUserSetting() {
        apiUserConfig()
            .then((res: any) => {
                this.form = res
                if (this.form.invite_appoint_user === null) {
                    this.form.invite_appoint_user = []
                } else if (
                    typeof this.form.invite_appoint_user[0] === 'string'
                ) {
                    // this.form.invite_appoint_user = []
                }
            })
            .catch(() => {
                this.$message.error('数据请求失败!')
            })
    }

    // 修改用户提现设置
    setUserSetting() {
        if (this.form.invite_ways === 1) {
            this.form.invite_appoint_user = []
        }
        this.form.scene = 'user' // 场景：user-用户设置；register-注册设置；withdraw-提现设置
        apiUserConfigSet(this.form).then((res: any) => {
            setTimeout(() => {
                this.getUserSetting()
            }, 50)
        })
    }

    getUserLevelLists() {
        apiUserLevelList({}).then((res: any) => {
            this.userLevelLists = res.lists
        })
    }

    handleUserLevel(event: any) {
        const row = this.userLevelLists.map((item: { id: any }) => item.id)
        this.form.invite_appoint_user = this.getArrEqual(row, event).map(String)
    }

    getArrEqual(arr1: any, arr2: any) {
        const newArr = []
        for (let i = 0; i < arr2.length; i++) {
            for (let j = 0; j < arr1.length; j++) {
                if (arr1[j] == arr2[i]) {
                    newArr.push(arr1[j])
                }
            }
        }
        return newArr
    }

    /** S Life Cycle **/
    created() {
        this.getUserSetting()
        this.getUserLevelLists()
    }
    // mounted() {
    // 	this.form.invite_appoint_user = [1,2,3]
    // }
    /** E Life Cycle **/
}
